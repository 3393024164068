import { LoginForm } from 'ui/components/layout/login-form/LoginForm';
import RaceCar from 'assets/img/race-car.svg';
import styles from './login-page.module.css';
import { IonContent, IonPage } from '@ionic/react';

export const LoginPage = () => (
  <IonPage>
    <IonContent className="overflow-y-auto overflow-x-hidden md:overflow-hidden">
      <section className={`${styles.loginPage} m-auto w-full md:w-[1000px] p-4`}>
        <div className="flex flex-col justify-center">
          <p id="h1-small">Welcome to</p>
          <h1 className="text-8xl md:text-12xl">
            ESL-<span className={styles.accent}>R1</span> Client
          </h1>
        </div>
        <section className="flex flex-col md:flex-row justify-center md:justify-start items-center mt-4">
          <LoginForm />
          <img src={RaceCar} className="w-screen md:w-[500px] m-8" alt="" />
        </section>
      </section>
    </IonContent>
  </IonPage>
);
