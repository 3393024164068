import React, { useCallback } from 'react';
import { IonCol, IonGrid, IonIcon, IonRow, useIonAlert } from '@ionic/react';
import { expandOutline, imageOutline, megaphoneOutline, readerOutline } from 'ionicons/icons';
import { Tooltip } from 'antd';
import { InfoBox } from './InfoBoxSection';
import supabase from 'services/supabase-config';

type InfoBoxCardProps = {
  infoBox: InfoBox;
  onDidUpdateDB: () => void;
};

const InfoBoxCard: React.FC<InfoBoxCardProps> = ({ infoBox, onDidUpdateDB }) => {
  const [presentAlert] = useIonAlert();

  const handleInputTexts = useCallback(async () => {
    await presentAlert({
      header: 'Update texts',
      subHeader: `You are editing the texts for info box with id ${infoBox.id}.`,
      cssClass: 'custom-alert-dialog',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Update Texts',
          role: 'confirm',
          handler: postTextData,
        },
      ],
      inputs: [
        {
          name: 'header',
          label: 'Header',
          type: 'text',
          value: infoBox.header,
          placeholder: '..',
        },
        {
          name: 'body',
          label: 'Body Text',
          type: 'textarea',
          value: infoBox.body_text,
          placeholder: '..',
        },
        {
          name: 'image',
          label: 'Image URL',
          type: 'text',
          value: infoBox.image,
          placeholder: '..',
        },
      ],
    });
  }, [infoBox, presentAlert]);

  const postTextData = async (value: any) => {
    const headerValue = value['header'];
    const bodyValue = value['body'];
    const imageValue = value['image'];
    await supabase.from('Infobox').update({ header: headerValue, body_text: bodyValue, image: imageValue }).eq('id', infoBox.id);
    onDidUpdateDB();
  };

  return (
    <div className="bg-primary h-[50px] min-h-[50px] rounded flex flex-row items-center my-1 overflow-hidden">
      <IonGrid className="px-0">
        <Tooltip placement="bottomLeft" title="Click to edit this infobox" className="p-2 rounded hover:bg-tertiary cursor-pointer">
          <IonRow onClick={handleInputTexts} className="w-full px-2">
            <IonCol className="flex items-center overflow-hidden" size="11.5">
              <div className="w-[90px] max-w-[90px] min-w-[90px] flex flex-row">
                <p className="font-bold">INFO-{infoBox.id}</p>
              </div>
              <div className="flex flex-row mr-2">
                <IonIcon icon={megaphoneOutline} className="mr-2 text-lg" />
                <p className="w-[250px] max-w-[250px] min-w-[250px] truncate">{infoBox?.header}</p>
              </div>
              <span className="flex flex-row items-center">
                <IonIcon icon={readerOutline} className="mr-2 text-xl" />
                <p className="w-[300px] max-w-[300px] min-w-[300px] truncate">{infoBox?.body_text}</p>
              </span>
              <span className="flex flex-row items-center">
                <IonIcon icon={imageOutline} className="mr-2 text-xl" />
                <p className="w-[300px] max-w-[300px] min-w-[300px] truncate">{infoBox?.image && infoBox.image !== '' ? infoBox.image : 'No Image'}</p>
              </span>
            </IonCol>
            <IonCol size="0.5" className="flex justify-start">
              <IonIcon icon={expandOutline} className="text-xl" />
            </IonCol>
          </IonRow>
        </Tooltip>
      </IonGrid>
    </div>
  );
};
export default InfoBoxCard;
