enum ESupabaseSettings {
  PROJECT_ID = 'wbhxkslncnettecsvowu',
  URL = 'https://wbhxkslncnettecsvowu.supabase.co',
  PUBLIC_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6IndiaHhrc2xuY25ldHRlY3N2b3d1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1NjQ3NTEsImV4cCI6MjAwOTE0MDc1MX0.NqkppU4HJ6Dnf09r9sySYGa0hbruqnqL8VoqgyKxBmA',
}

export { ESupabaseSettings };

export const Version = '2.0.8';

// Set the build mode
export const buildMode: 'desktop' | 'web' | 'gfx' = 'web';
