import { useEffect, useState } from 'react';
import supabase from 'services/supabase-config';
import { IonIcon, useIonAlert, useIonLoading, useIonRouter } from '@ionic/react';
import { eyeOutline } from 'ionicons/icons';
import { useAuthUserStore } from 'state/user';
import ButtonFilled from 'ui/components/buttons/regular-button-filled/ButtonFilled';
import styles from './login-form.module.css';
import { buildMode } from 'settings';

export const LoginForm: React.FC = () => {
  const router = useIonRouter();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);

  const togglePassword = () => setIsPasswordShown(!isPasswordShown);

  const [present, dismiss] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const setAuthUser = useAuthUserStore((state) => state.setAuthUser);

  useEffect(() => {
    if (email !== '' && password !== '') return setIsEmpty(false);
    else return setIsEmpty(true);
  }, [email, password]);

  const handleLogin = async () => {
    await present({ cssClass: 'custom-alert-dialog', message: 'Logger ind...' });

    const { data, error } = await supabase.auth.signInWithPassword({ email, password });

    if (data.user && data.user.aud === 'authenticated') {
      setAuthUser(data.user);
      await dismiss();
      router.push(buildMode === 'desktop' ? '/controller' : '/admin');
    } else {
      await dismiss();
      await presentAlert({
        cssClass: 'custom-alert-dialog',
        header: 'Login failed',
        message: error?.message,
        buttons: ['OK'],
        backdropDismiss: true,
      });
    }
  };

  return (
    <form className={`${styles.loginform} w-96 p-8 gap-4 flex flex-col justify-start rounded bg-secondary`}>
      <div className="flex flex-col justify-start items-start gap-2">
        <label htmlFor="text">
          <p>Email</p>
        </label>
        <input
          autoFocus
          className="w-80 bg-primary rounded p-2 gap-2"
          type="email"
          name="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value ?? '')}
          required
        />
      </div>
      <div className="flex flex-col justify-start items-start gap-2 mb-2">
        <label htmlFor="password">
          <p>Password</p>
        </label>
        <div className="relative flex items-center gap-2">
          <input
            className="w-80 bg-primary rounded p-2"
            type={isPasswordShown ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value ?? '')}
            required
          />
          <div className="absolute right-4 flex items-center cursor-pointer" onClick={togglePassword}>
            <IonIcon icon={eyeOutline} size="small" />
          </div>
        </div>
      </div>
      <ButtonFilled
        buttonType="submit"
        onClick={(e) => {
          e?.preventDefault();
          handleLogin();
        }}
        disabled={isEmpty}
        tippyText={isEmpty ? 'All fields are required' : undefined}
      >
        Login
      </ButtonFilled>
    </form>
  );
};
