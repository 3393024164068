import React, { useEffect, useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import InfoSection from './sections/InfoSection';
import styles from '../shared-styles.module.css';
import { fetchFullCurrentLeaderboard, RaceInfo } from 'services/api-fetches';
import { supabaseAnonClient } from 'services/supabase-config';
import { Version } from 'settings';

type PageWrapperProps = {
  useCurrentRace?: boolean;
  currentRace?: RaceInfo;
};

const PageWrapper: React.FC<PageWrapperProps> = ({ useCurrentRace = true, currentRace, children }) => {
  const [contestName, setContestName] = useState<string>();
  const [inGameStageName, setInGameStageName] = useState<string>();
  const [stageCountdown, setStageCountdown] = useState<number | undefined>();
  const [startLight, setStartLight] = useState<number | undefined>();
  const [raceId, setRaceId] = useState<number | undefined>();

  useEffect(() => {
    if (!useCurrentRace) return;
    const currentRace = setInterval(() => {
      fetchFullCurrentLeaderboard().then((cr: any) => {
        if (!cr) return;
        setContestName(cr.Race.Contest.name);
        setRaceId(cr.Race.id);
        setStageCountdown(cr.Race.RaceCountdown?.stage_time_left);
        setStartLight(cr.Race.RaceCountdown?.start_light);
      });
    }, 1000);
    return () => clearInterval(currentRace);
  }, [useCurrentRace]);

  useEffect(() => {
    if (useCurrentRace) return;
    const _currentRace = currentRace as any; // For the type issue
    setContestName(_currentRace?.Contest?.name);
    setRaceId(currentRace?.id);
  }, [currentRace, useCurrentRace]);

  useEffect(() => {
    if (!raceId) {
      setContestName(undefined);
      return;
    }

    setStageCountdown(undefined);
    setStartLight(undefined);
    setInGameStageName('');

    supabaseAnonClient
      .channel('custom-filter-channel')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'RaceCountdown',
          filter: `race=eq.${raceId}`,
        },
        (payload) => {
          const { start_light, stage_time_left, stage } = payload.new as any;

          setStageCountdown(stage_time_left);
          setStartLight(start_light);
          setInGameStageName(stage);
        }
      )
      .subscribe();
  }, [raceId]);

  return (
    <IonPage>
      <IonContent>
        <p className="absolute top-2 left-2">Version {Version}</p>
        <section className={`${styles.homepage} m-auto pb-8 pt-4 flex flex-col`}>
          <div className={styles.paddedSides}>
            <InfoSection contestName={contestName} stageName={inGameStageName} stageCountdown={stageCountdown} startLight={startLight} />
          </div>
          {children}
        </section>
      </IonContent>
    </IonPage>
  );
};
export default PageWrapper;
