import React, { useCallback, useEffect, useState } from 'react';
import styles from './info-box-section.module.css';
import { ArrayElement, supabaseAnonClient } from 'services/supabase-config';
import InfoBoxCard from './InfoBoxCard';

export type InfoBoxes = Awaited<ReturnType<typeof fetchInfoBoxes>>['data'];
export type InfoBox = ArrayElement<InfoBoxes>;
const fetchInfoBoxes = async () => supabaseAnonClient.from('Infobox').select('*').order('id');

const InfoBoxSection: React.FC = () => {
  const [infoBoxes, setInfoBoxes] = useState<InfoBoxes>([]);

  useEffect(() => {
    refreshDB();
  }, []);

  const refreshDB = useCallback(() => {
    fetchInfoBoxes().then((r) => setInfoBoxes(r.data ?? []));
  }, []);

  return (
    <div className={`flex flex-row w-full ${styles.contentArea}`}>
      <section className="w-full flex flex-col overflow-y-auto">
        {infoBoxes?.map((box, i) => (
          <InfoBoxCard key={i} infoBox={box} onDidUpdateDB={refreshDB} />
        ))}
      </section>
    </div>
  );
};
export default InfoBoxSection;
