import { RaceParticipant } from 'services/api-fetches';
import supabase, { Player } from 'services/supabase-config';
import React, { useCallback } from 'react';
import { IonCol, IonGrid, IonIcon, IonRow, useIonAlert } from '@ionic/react';
import { carSportOutline, closeOutline, gameControllerOutline, personCircleOutline, ribbonOutline, squareOutline, trophyOutline } from 'ionicons/icons';
import { Tooltip } from 'antd';

import styles from './participant-card.module.css';

type ParticipantCardProps = {
  participant: RaceParticipant;
  player: Player;
  onDidUpdateDB: () => void;
};

const ParticipantCard: React.FC<ParticipantCardProps> = ({ participant, player, onDidUpdateDB }) => {
  const [presentAlert] = useIonAlert();

  const handleRemoveClick = useCallback(async () => {
    await presentAlert({
      header: 'Are you sure you wish to remove this participant from the race?',
      cssClass: 'custom-alert-dialog',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: removeParticipant,
        },
      ],
    });
  }, []);

  const handleAssignPointsClick = useCallback(async () => {
    await presentAlert({
      header: 'Assign points',
      subHeader: 'Enter the number of points to assign to the player for this race',
      cssClass: 'custom-alert-dialog',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Assign',
          role: 'confirm',
          handler: assignPoints,
        },
      ],
      inputs: [
        {
          type: 'number',
          min: 0,
          value: participant.points,
          placeholder: '..',
        },
      ],
    });
  }, [participant]);

  const handleAssignRigClick = useCallback(async () => {
    await presentAlert({
      header: 'Assign rig',
      subHeader: 'Enter the rig number for this player for this race',
      cssClass: 'custom-alert-dialog',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Assign',
          role: 'confirm',
          handler: assignRig,
        },
      ],
      inputs: [
        {
          type: 'number',
          min: 0,
          value: participant.rig,
          placeholder: '..',
        },
      ],
    });
  }, [participant]);

  const handleAssignFinalistModeClick = useCallback(async () => {
    await presentAlert({
      header: 'Set finalist mode',
      subHeader: 'Set the player as a finalist en the entire dataset.',
      cssClass: 'custom-alert-dialog',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Assign',
          role: 'confirm',
          handler: setFinalistMode,
        },
      ],
      inputs: [
        {
          type: 'radio',
          label: 'Finalist',
          value: true,
          cssClass: styles.radioBtn,
        },
        {
          type: 'radio',
          label: 'Not finalist',
          value: false,
          cssClass: styles.radioBtn,
        },
      ],
    });
  }, [participant]);

  const removeParticipant = async () => {
    console.log('Removing participant: ' + participant.id);
    await supabase.from('RaceParticipant').delete().eq('id', participant.id);
    onDidUpdateDB();
  };

  const assignPoints = async (value: any) => {
    if (value['0'] === null || value['0'] === undefined || value['0'] === '') return;
    console.log(`Assigning ${value['0']} points to participant: ${participant.id}`);
    await supabase
      .from('RaceParticipant')
      .update({ points: value ? value['0'] : 0 })
      .eq('id', participant.id);
    onDidUpdateDB();
  };

  const assignRig = async (value: any) => {
    if (value['0'] === null || value['0'] === undefined || value['0'] === '') return;
    console.log(`Assigning rig nr. ${value['0']} to participant: ${participant.id}`);
    await supabase
      .from('RaceParticipant')
      .update({ rig: value ? value['0'] : 0 })
      .eq('id', participant.id);
    onDidUpdateDB();
  };

  const setFinalistMode = async (value: any) => {
    console.log(`Assigning finalist mode ${value} to player: ${player.id}`);
    await supabase.from('Player').update({ is_finalist: value }).eq('id', player.id);
    onDidUpdateDB();
  };

  return (
    <div className="bg-primary h-[50px] rounded flex flex-row items-center my-1 px-2">
      <IonGrid>
        <IonRow>
          <IonCol className="flex items-center" size="11">
            <div className="w-[80px] flex flex-row">
              <IonIcon icon={carSportOutline} className="mr-2 text-lg" />
              <p>{player.car_number}</p>
            </div>
            <div className="flex flex-row mr-2">
              <IonIcon icon={personCircleOutline} className="mr-2 text-lg" />
              <p className="w-[90px] truncate">{player?.display_name}</p>
            </div>
            <Tooltip title="Click to set points" className="p-2 rounded hover:bg-tertiary cursor-pointer">
              <span className="flex flex-row items-center" onClick={handleAssignPointsClick}>
                <IonIcon icon={ribbonOutline} className="mr-2 text-xl" />
                <p className="w-[30px]">{participant.points}</p>
              </span>
            </Tooltip>
            <Tooltip title="Click to assign rig" className="p-2 rounded hover:bg-tertiary cursor-pointer">
              <span className="flex flex-row items-center" onClick={handleAssignRigClick}>
                <IonIcon icon={gameControllerOutline} className="mr-2 text-xl" />
                <p>{participant.rig ?? '...'}</p>
              </span>
            </Tooltip>
            <Tooltip title="Click set finalist mode" className="p-2 rounded hover:bg-tertiary cursor-pointer">
              <span className="flex flex-row items-center" onClick={handleAssignFinalistModeClick}>
                <IonIcon icon={player.is_finalist ? trophyOutline : trophyOutline} className="mr-2 text-xl" />
                <p>{player.is_finalist ? 'Finalist' : '...'}</p>
              </span>
            </Tooltip>
          </IonCol>
          <IonCol size="1" className="flex justify-end">
            <Tooltip title="Remove participant">
              <button className="w-[35px] h-[35px] bg-red-500 hover:bg-red-400 rounded text-2xl flex justify-center items-center" onClick={handleRemoveClick}>
                <IonIcon icon={closeOutline} />
              </button>
            </Tooltip>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};
export default ParticipantCard;
