import { Contest, fetchParticipants, fetchRacesForContest, Race, RaceParticipants, Races } from 'services/api-fetches';
import React, { useEffect, useState } from 'react';
import styles from './manage-contest.module.css';
import ParticipantCard from 'ui/pages/admin-page/sections/manage-contest-section/ParticipantCard';
import supabase, { Player, Stage } from 'services/supabase-config';
import { Button, Select } from 'antd';
import { keyOutline, pushOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

type ManageContentSectionProps = {
  contest: Contest;
};

const ManageContestSection: React.FC<ManageContentSectionProps> = ({ contest }) => {
  const [races, setRaces] = useState<Races>([]);
  const [activeRace, setActiveRace] = useState<Race>();
  const [participants, setParticipants] = useState<RaceParticipants>([]);
  const [availablePlayers, setAvailablePlayers] = useState<Player[]>([]);
  const [selectedPlayer, setSelectedPlayer] = useState<Player>();
  const [inputMaxLaps, setInputMaxLaps] = useState<number>();

  useEffect(() => {
    fetchRacesForContest(contest.id).then((r) => {
      setRaces(r.data);
      setActiveRace(r.data?.[0]);
    });
  }, [contest.id]);

  useEffect(() => {
    if (activeRace?.id === undefined || contest === undefined) {
      setParticipants([]);
      setInputMaxLaps(undefined);
      return;
    }

    fetchParticipants(activeRace.id).then((r) => setParticipants(r.data));
  }, [activeRace, contest]);

  useEffect(() => {
    void refreshAvailablePlayers();
  }, [participants]);

  const refreshAvailablePlayers = async () => {
    const { data } = await supabase.from('Player').select('*').order('car_number', { ascending: true });
    if (!data) return;
    const players: Player[] = data.filter((p) => !participants?.some((part) => part.player === p.id));
    setAvailablePlayers(players);
  };

  const onChange = (value: number) => setSelectedPlayer(availablePlayers.find((p) => p.id === value));

  const addParticipant = async () => {
    if (!activeRace || !selectedPlayer) return;
    await supabase.from('RaceParticipant').insert([{ race: activeRace.id, player: selectedPlayer.id, points: 0 }]);
    await fetchParticipants(activeRace.id).then((r) => setParticipants(r.data));
    await supabase.from('RaceParticipant').select('*, Player (*)').eq('race', activeRace.id).order('race', { ascending: true });
    setSelectedPlayer(undefined);
  };

  const pushRigsToCompanion = async () => {
    const { data, error } = await supabase.from('ExtraHTTP').select('*').eq('id', 1).single();
    console.log('Found setting: ', data);
    console.log('Pushing configs to companion: ');

    const url = data?.url ?? '';
    if (!participants) return;

    for (const p of participants) {
      const player = p.Player as any;

      console.log(`Player car ${player.car_number} to rig ${p.rig}`);
      const urlWPidNRig = url.replace('$pid$', player.car_number.toString() ?? '');
      const urlWPidNRigWPid = urlWPidNRig.replace('$rig$', p.rig?.toString() ?? '');
      console.log(urlWPidNRigWPid);
      try {
        void fetch(urlWPidNRigWPid, { method: 'GET', mode: 'no-cors' }).catch();
      } catch (e) {
        // Do nothing
      }
    }
  };

  return (
    <div className={`flex flex-row w-full ${styles.contentArea}`}>
      <section className="w-[350px] flex flex-col">
        <h5 className="text-center mb-2">{activeRace ? 'Select race' : 'No races'}</h5>
        <div className="overflow-x-hidden overflow-y-auto p-2 h-full">
          {races?.map((r) => (
            <button
              key={r.id}
              onClick={() => setActiveRace(r)}
              className={`
                w-full h-[50px] my-1 rounded hover:bg-primary-shade flex flex-row items-center justify-start px-2
                ${activeRace?.id === r.id ? 'bg-primary-shade' : 'bg-primary'}
                ${activeRace?.id === r.id ? styles.cardPickerSelected : styles.cardPicker}
              `}
            >
              <span className="text-base flex flex-row items-center">
                <IonIcon icon={keyOutline} className="mr-1" />
                {r.id}
              </span>
              <span className="mx-2">|</span>
              <span className="truncate text-md flex flex-row items-center pb-0.5">{r.title}</span>
            </button>
          ))}
        </div>
      </section>
      <section className="w-full flex flex-col">
        {activeRace && (
          <>
            <h5 className="text-center mb-2">
              ({participants?.length ?? 0}) Participants for &quot;{activeRace?.title}&quot;
            </h5>
            <div className={'flex flex-col'}>
              {/** Todo: The max laps in db is legacy, and can be removed when we have time */}

              <div className="flex flex-row justify-between">
                <div className={'m-0 ml-6 bg-secondary rounded flex items-center my-1'}>
                  <Button className="bg-tertiary mr-2 font-bold w-[150px] max-w-[150px] min-w-[150px]" disabled={!selectedPlayer} onClick={addParticipant}>
                    Add Participant
                  </Button>
                  <Select
                    className="w-[250px]"
                    showSearch
                    placeholder="Select a player"
                    optionFilterProp="children"
                    value={selectedPlayer?.id}
                    onChange={onChange}
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={availablePlayers.map((p) => ({ label: `${p.car_number} - ${p.display_name}`, value: p.id })) ?? []}
                  />
                </div>

                <Button className="bg-primary flex items-center bg-accent-base" onClick={pushRigsToCompanion}>
                  <IonIcon icon={pushOutline} className="text-lg mr-1" />
                  <span>Push Rigs To Companion</span>
                </Button>
              </div>
            </div>

            <div className="p-2 pl-6 overflow-y-auto overflow-x-hidden h-full">
              <div className="flex flex-col ">
                {participants?.map((p) => (
                  <ParticipantCard
                    key={p.id}
                    participant={p}
                    player={p.Player as Player}
                    onDidUpdateDB={() => fetchParticipants(activeRace.id).then((r) => setParticipants(r.data))}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </section>
    </div>
  );
};
export default ManageContestSection;
