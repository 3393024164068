import React, { useEffect, useMemo, useState } from 'react';
import { IonContent, IonIcon, IonPage, useIonRouter } from '@ionic/react';
import { useAuthUserStore } from 'state/user';
import { Layout, Menu, MenuProps } from 'antd';
import styles from './admin-page.module.css';
import {
  barChartOutline,
  bookOutline,
  carSportOutline,
  documentsOutline,
  homeOutline,
  megaphoneOutline,
  optionsOutline,
  peopleOutline,
  powerOutline,
  terminalOutline,
} from 'ionicons/icons';
import HomeSection from './sections/HomeSection';
import UserGuideSection from './sections/UserGuideSection';
import ManageLeaderboardSection from './sections/ManageLeaderboardSection';
import ButtonSettingsSection from './sections/ButtonSettingsSection';
import HttpSettingsSection from './sections/HttpSettingsSection';
import LiveGraphicsSection from './sections/LiveGraphicsSection';
import DocumentationSection from './sections/DocumentationSection';
import LogoutSection from './sections/LogoutSection';
import { Contests, fetchContests } from 'services/api-fetches';
import ManageContestSection from './sections/manage-contest-section/ManageContestSection';
import supabase from 'services/supabase-config';
import InfoBoxSection from './sections/infobox-section/InfoBoxSection';
import ManagePlayerSection from './sections/manage-player-section/ManagePlayerSection';
import SubtSection from './sections/subt-section/SubtSection';

type MenuItem = Required<MenuProps>['items'][number];

const AdminPage: React.FC = () => {
  const router = useIonRouter();
  const authUser = useAuthUserStore((state) => state.authUser);
  const { Sider } = Layout;

  const [activePageKey, setActivePageKey] = useState('0');
  const [collapsed, setCollapsed] = useState(false);
  const [contests, setContests] = useState<Contests>([]);

  // Holds the active page content, changing only based on the activePageKey changes
  const activeContent: JSX.Element = useMemo(() => PageMap.get(activePageKey)?.content || <></>, [activePageKey]);

  // Fetch contests on load
  useEffect(() => void fetchContests().then((res) => setContests(res.data ?? [])), []);

  // Maps the contests into the PageMap, so they can be retrieved and rendered. Changes when the contests change.
  useEffect(() => {
    contests?.forEach((c) => PageMap.set(`c-${c.id}`, { title: c.name, content: <ManageContestSection contest={c} /> }));
    PageMap.set('9', { title: 'Log out', content: <LogoutSection onCancel={() => setActivePageKey('0')} /> });
  }, [contests]);

  // Creates menu items for all the pages in the PageMap. Note that the contests are dynamically generated as sub-items of the Manage Contest menu item.
  const items: MenuItem[] = useMemo(
    () => [
      getItem(PageMap.get('0')?.title, '0', <IonIcon className={styles.sideIcon} icon={homeOutline} />),
      getItem(PageMap.get('1')?.title, '1', <IonIcon className={styles.sideIcon} icon={bookOutline} />),
      getItem(PageMap.get('2')?.title, '2', <IonIcon className={styles.sideIcon} icon={peopleOutline} />, [getItem('Players', '2-1')]),
      getItem(
        'Manage Contest',
        '3',
        <IonIcon className={styles.sideIcon} icon={carSportOutline} />,
        contests?.map((con) => getItem(con.name, `c-${con.id}`))
      ),
      getItem(PageMap.get('4')?.title, '4', <IonIcon className={styles.sideIcon} icon={barChartOutline} />),
      getItem(PageMap.get('5')?.title, '5', <IonIcon className={styles.sideIcon} icon={optionsOutline} />),
      getItem(PageMap.get('6')?.title, '6', <IonIcon className={styles.sideIcon} icon={terminalOutline} />),
      getItem(PageMap.get('7')?.title, '7', <IonIcon className={styles.sideIcon} icon={megaphoneOutline} />, [
        getItem('Info Boxes', '7-1'),
        getItem('Subts', '7-2'),
      ]),
      getItem(PageMap.get('8')?.title, '8', <IonIcon className={styles.sideIcon} icon={documentsOutline} />),
      getItem(PageMap.get('9')?.title, '9', <IonIcon className={styles.sideIcon} icon={powerOutline} />),
    ],
    [contests]
  );

  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      if (!data.session) router.push('/login');
    });
  }, [router, authUser]);
  return (
    <IonPage>
      <IonContent>
        <Layout style={{ minHeight: '100vh' }} hasSider>
          <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} className={styles.sider}>
            <div className="m-3 p-2 rounded">
              <p className={`font-bold text-center overflow-hidden whitespace-nowrap ${styles.neon}`}>{collapsed ? '@' : authUser?.email}</p>
            </div>
            <Menu
              selectedKeys={[activePageKey]}
              onClick={(event) => setActivePageKey(event.key)}
              theme="dark"
              defaultSelectedKeys={['0']}
              mode="inline"
              items={items}
            />
          </Sider>
          <Layout className={`${styles.content} p-8 ${collapsed ? styles.collapsed : styles.extended}`}>
            <h2 className={styles.neon}>{PageMap.get(activePageKey)?.title}</h2>
            <hr className="mb-3 mt-0 opacity-70" />
            <section>{activeContent}</section>
          </Layout>
        </Layout>
      </IonContent>
    </IonPage>
  );
};
export default AdminPage;

type PageInfo = {
  title: string;
  content?: JSX.Element;
};

/** Hold the components and titles for all the static pages. The dynamic ones are generated inside the AdminPage. */
const PageMap = new Map<string, PageInfo>([
  ['0', { title: 'Admin Dashboard', content: <HomeSection /> }],
  ['1', { title: 'User Guide', content: <UserGuideSection /> }],
  ['2', { title: 'Base Data', content: <HomeSection /> }],
  ['2-1', { title: 'Players', content: <ManagePlayerSection /> }],
  ['3', { title: 'Manage Contest', content: <HomeSection /> }],
  ['4', { title: 'Manage Leaderboard', content: <ManageLeaderboardSection /> }],
  ['5', { title: 'Button Settings', content: <ButtonSettingsSection /> }],
  ['6', { title: 'HTTP Settings', content: <HttpSettingsSection /> }],
  ['7', { title: 'Live Graphics', content: <LiveGraphicsSection /> }],
  ['7-1', { title: 'Info Boxes', content: <InfoBoxSection /> }],
  ['7-2', { title: 'Subts', content: <SubtSection /> }],
  ['8', { title: 'Documentation', content: <DocumentationSection /> }],
]);

// Helper function to create a MenuItem
const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], disabled?: boolean): MenuItem => ({
  key,
  icon,
  children,
  label,
  disabled,
});
