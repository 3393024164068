import Tippy from '@tippyjs/react';
import styles from './button-filled.module.css';
import { MouseEvent } from 'react';

export type ButtonProps = {
  className?: string;
  onClick?: (e?: MouseEvent) => void;
  disabled?: boolean;
  buttonType?: 'submit' | 'button' | 'reset';
  tippyText?: string;
  height?: string;
  width?: string;
  padding?: string;
  paddingY?: string;
  isSelected?: boolean;
};

const ButtonFilled: React.FC<ButtonProps> = ({
  onClick,
  disabled = false,
  children,
  buttonType = 'button',
  tippyText,
  className = '',
  width = 'full',
  height = '9',
  padding = '1',
  paddingY = '1',
  isSelected = false,
}) => (
  <button
    className={`${styles.button} bg-accent-base hover:bg-accent-base-hover w-${width} h-${height} p-${padding} py-${paddingY} rounded ${
      isSelected ? styles.selected : styles.deselected
    } ${className}`}
    type={buttonType}
    disabled={disabled}
    onClick={onClick}
  >
    {tippyText ? (
      <Tippy content={tippyText} placement="bottom">
        <p>{children}</p>
      </Tippy>
    ) : (
      <p>{children}</p>
    )}
  </button>
);
export default ButtonFilled;
