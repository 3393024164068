export type UDPConfig = {
  port: number;
  host: string;
  password: string;
};

export enum EMessageType {
  'RegisterClient' = 'RegisterClient',
  'EnableAutoDirector' = 'EnableAutoDirector',
  'ChangeFollowedPlayer' = 'ChangeFollowedPlayer',
  'ChangeCamera' = 'ChangeCamera',
  'GameplayStageLiveUpdate' = 'GameplayStageLiveUpdate',
  'GameplayStageInfoUpdate' = 'GameplayStageInfoUpdate',
  'CarLiveUpdate' = 'CarLiveUpdate',
  'StartLightUpdate' = 'StartLightUpdate',
}

export type UDPMessage = {
  version?: number;
  messageType: EMessageType;
  data: any;
};

export type UDPConnector = {
  sendUDPMessage: (message: UDPMessage) => void;
};

export const makeUDPConnector = (onMessageReceived: (message: UDPMessage) => void, udpConfig: UDPConfig): UDPConnector => {
  const { customUDPBridge } = window as any;

  customUDPBridge.onMessageReceived((_event: any, msg: UDPMessage) => onMessageReceived(msg));

  customUDPBridge.createUDPClient(udpConfig);

  console.log('UDP Setup Ready..');
  return customUDPBridge;
};

export type GameplayStageLiveUpdate = {
  timeLeft: number;
  lapsLeft: number;
};

export type GameplayStageInfoUpdate = {
  contestName: string;
  gameplayStageName: 'Practice' | 'Qualifications' | 'Race';
  track: string;
  balanceOfPerformanceData: {
    vehicleToBalance: string;
    additionalBalast: number;
    enginePowerPct: number;
  }[];
};

export type StartLightUpdate = {
  startLightCountdown: number;
};

export type CarLiveUpdate = {
  totalTime: number;
  carNumber: number; // On-car number
  userId: string;
  carId: string;
  position: number;
  completedLaps: number;
  currentLap: Lap;
  bestLap: Lap;
  lastLap: Lap;
  lapProgress: number; // Lap completion percentage as a fraction
  fontTireType: string;
  rearTireType: string;
  gap: number; // Gap to leader in seconds
  gAP: number; // Will be removed when they fix it..
  interval: number; // Gap to car in front, in seconds
  speed: number; // Speed in km/h
  engineRPM: number;
  inPitStatus: 'NotInPit' | 'EnteringPitLane' | 'InPitLane' | 'PerformingPitStop' | 'InPitGarage' | 'ExitingPitLane' | string;
  disqualified: boolean;
  didNotFinish: boolean;
  pendingPenalties: Penalty[];
  servedPenalties: Penalty[];
  sumTimePenaltiesAdded: number; // Total sum of time penalties added to final score of players (can be added after finishing gameplay)
  steeringInput: number; // [-1...1] Current steering input read from user
  throttleInput: number; // [0...1] Current throttle input read from user
  brakeInput: number; // [0...1] Current brake input read from user
  clutchInput: number; // [0...1] Current clutch input read from user
  gear: number; // Current gear. 0 = neutral, -1 = reverse
  barycentricCoordinates: { x: number; y: number; z: number };
};

export type Lap = {
  time: number; // Lap time (in seconds) - or -1 if no valid lap set yet
  sectors: number[];
};

export type Penalty = {
  penaltyReason: string;
  penaltyName: string;
};
