import React, { useEffect, useState } from 'react';
import { Checkbox, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import PageWrapper from '../page-wrapper/PageWrapper';
import styles from '../shared-styles.module.css';
import ButtonFilled from '../../components/buttons/regular-button-filled/ButtonFilled';
import { IonIcon, useIonRouter } from '@ionic/react';
import { chevronForward } from 'ionicons/icons';
import { buildMode } from 'settings';
import {
  convertRaceToLabel,
  convertToMMSS,
  convertToPercentage,
  fetchAllRacesInfo,
  fetchFullLeaderboardForRace,
  fetchStages,
  RaceInfo,
  RacesInfo,
  Stages,
} from 'services/api-fetches';
import { RaceCountdown, supabaseAnonClient } from 'services/supabase-config';

type LeaderboardEntry = {
  key: number;
  placement: number;
  carNumber: number;
  name: string;
  completedLaps: number;
  lapProgress: string;
  bestLapTime: string;
  currentLapTime: string;
  gapToLeader: string;
  intervalToNext: string;
  inPit: string;
};

const columns: ColumnsType<LeaderboardEntry> = [
  {
    title: 'Placement',
    dataIndex: 'placement',
    key: 'placement',
  },
  {
    title: 'Car Nr.',
    dataIndex: 'carNumber',
    key: 'carNumber',
  },
  {
    title: 'Player Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Completed Laps',
    dataIndex: 'completedLaps',
    key: 'completedLaps',
  },
  {
    title: 'Lap Progress',
    dataIndex: 'lapProgress',
    key: 'lapProgress',
  },
  {
    title: 'Best Lap Time',
    dataIndex: 'bestLapTime',
    key: 'bestLapTime',
  },
  {
    title: 'Current Lap Time',
    dataIndex: 'currentLapTime',
    key: 'currentLapTime',
  },
  {
    title: 'Gap to Leader',
    dataIndex: 'gapToLeader',
    key: 'gapToLeader',
  },
  {
    title: 'Interval to Next',
    dataIndex: 'intervalToNext',
    key: 'intervalToNext',
  },
  {
    title: 'In Pit',
    dataIndex: 'inPit',
    key: 'inPit',
  },
];

const LeaderboardPage: React.FC = () => {
  const router = useIonRouter();

  const [races, setRaces] = useState<RacesInfo>([]);
  const [stages, setStages] = useState<Stages>([]);
  const [selectedRace, setSelectedRace] = useState<number | undefined>();
  const [selectedStage, setSelectedStage] = useState<string | undefined>();
  const [followCurrentRace, setFollowCurrentRace] = useState<boolean>(true);
  const [tableData, setTableData] = useState<LeaderboardEntry[]>([]);

  const refreshLeaderboardData = async () => {
    // Check if we are set to follow the currently active race if we are, update selected.
    let tmpFollowCurrentRace = followCurrentRace;
    setFollowCurrentRace((prev) => {
      tmpFollowCurrentRace = prev;
      return prev;
    });
    if (tmpFollowCurrentRace) await SetToCurrentActiveRace();

    // Grab the currently selected race
    let tmpSelectedRace = selectedRace;
    setSelectedRace((prev) => {
      tmpSelectedRace = prev;
      return prev;
    });

    if (tmpSelectedRace === undefined) return;

    const { data, error } = await fetchFullLeaderboardForRace(tmpSelectedRace);
    if(error) console.error(error);

    let tmpStage = selectedStage;
    setSelectedStage((prev) => {
      tmpStage = prev;
      return prev;
    });

    if (tmpFollowCurrentRace) {
      const stage = (data?.RaceCountdown as RaceCountdown).stage;
      if (stage) {
        setSelectedStage(stage);
        tmpStage = stage;
      }
    }

    const dataUntyped: any = data;

    const participants = dataUntyped.RaceParticipant;

    let formattedData: LeaderboardEntry[] = [];
    participants.forEach((participant: any) => {
      const entry = Array.isArray(participant.LeaderboardEntry)
        ? participant.LeaderboardEntry.find((entry: any) => entry.stage === tmpStage)
        : participant.LeaderboardEntry;

      const player = participant.Player;

      formattedData.push({
        key: participant.id,
        placement: entry?.placement ?? 0,
        carNumber: player.car_number,
        name: player.display_name,
        completedLaps: entry?.completed_laps ?? 0,
        lapProgress: convertToPercentage(entry?.lap_progress ?? 0),
        bestLapTime: convertToMMSS(entry?.best_lap_time ?? 0),
        currentLapTime: convertToMMSS(entry?.current_lap_time ?? 0),
        gapToLeader: convertToMMSS(entry?.gap_to_leader ?? 0),
        intervalToNext: convertToMMSS(entry?.interval_to_next ?? 0),
        inPit: entry?.in_pit ?? '-',
      });
      formattedData = formattedData.sort((a, b) => a.placement - b.placement);
    });

    setTableData(formattedData);
  };

  useEffect(() => {
    // Get all the race info, and set the selected race to the current active race as default.
    fetchAllRacesInfo().then(async (res) => {
      setRaces(res.data);

      await fetchStages().then((res) => setStages(res.data));

      await SetToCurrentActiveRace();
    });

    const interval = setInterval(() => refreshLeaderboardData(), 700);

    // Set placeholder data
    const newData = [];
    for (let i = 0; i < 12; i++) {
      newData.push({
        key: i,
        placement: i + 1,
        carNumber: i,
        name: `player${i}`,
        completedLaps: 0,
        lapProgress: '0%',
        bestLapTime: '00:00',
        currentLapTime: '00:00',
        gapToLeader: '0s',
        intervalToNext: '0s',
        inPit: i % 2 === 0 ? '✓' : '✖',
      });
    }
    setTableData(newData);

    return () => clearInterval(interval);
  }, []);

  const SetToCurrentActiveRace = async () => {
    await supabaseAnonClient
      .from('CurrentRace')
      .select()
      .single()
      .then((res) => {
        setSelectedRace((prev) => res?.data?.race ?? prev);
      });
  };

  const getCurrentRace = (): RaceInfo | undefined => races?.find((race) => race.id === selectedRace);

  return (
    <PageWrapper useCurrentRace={false} currentRace={getCurrentRace()}>
      <div className={`${styles.paddedSides}`}>
        <div className="flex justify-center gap-4 mb-2">
          <div className="h-full flex flex-row justify-center items-center p-3 pr-0">
            <p className="mr-2">Follow Current Race: </p>
            <Checkbox checked={followCurrentRace} onChange={(event) => setFollowCurrentRace(event.target.checked)} className="mr-4" />
            <Select
              className={`w-[400px] ${followCurrentRace ? 'opacity-50' : ''}`}
              defaultValue={selectedRace}
              disabled={followCurrentRace}
              value={selectedRace}
              onChange={(value) => setSelectedRace(value)}
              options={races?.map((race) => ({ value: race.id, label: convertRaceToLabel(race) }))}
            />
            <Select
              className={`w-[150px] ml-2 ${followCurrentRace ? 'opacity-50' : ''}`}
              defaultValue={selectedStage}
              disabled={followCurrentRace}
              value={selectedStage}
              onChange={(value) => setSelectedStage(value)}
              options={stages?.map((stage) => ({ value: stage.name, label: stage.display_label }))}
            />
          </div>

          {buildMode === 'desktop' && (
            <ButtonFilled onClick={() => router.push('/controller')} width="auto" className="px-2 m-1">
              <span className="flex justify-center items-center">
                {'Control Panel'}
                <IonIcon icon={chevronForward} className="ml-1 text-lg" />
              </span>
            </ButtonFilled>
          )}
        </div>

        <Table dataSource={tableData} columns={columns} pagination={false} />
      </div>
    </PageWrapper>
  );
};
export default LeaderboardPage;
