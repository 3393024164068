import React, { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { useAuthUserStore } from 'state/user';
import { StatusOptions } from 'static-data/status-options';

import FollowBySections from './sections/FollowBySections';
import CameraSection from './sections/CameraSection';
import SpecialActionsSection from './sections/SpecialActionsSection';
import InitializerSection from './sections/InitializerSection';
import PageWrapper from '../page-wrapper/PageWrapper';

import styles from '../shared-styles.module.css';
import FabSection from '../page-wrapper/sections/FabSection';
import ConnectionModal from 'ui/components/modal/ConnectionModal';
import {
  CarLiveUpdate,
  EMessageType,
  GameplayStageInfoUpdate,
  GameplayStageLiveUpdate,
  makeUDPConnector,
  UDPConfig,
  UDPConnector,
  UDPMessage,
} from 'services/custom-udp-client';
import { APITriggerClient, ButtonAPITrigger, Contest, EButtonAPITriggerTypes, fetchButtonAPITriggers, Race, updateRaceCountdown } from 'services/api-fetches';
import { msgAutoDirector, msgChangeCamera, msgFollowPlayer } from 'services/udp-generators';
import supabase, { Player } from 'services/supabase-config';
import { subscribeToCurrentRace } from '../../../services/realtime-connections';

const carNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
const playerPlacements = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const cameras = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export enum EFollowBy {
  FOLLOW_BY_CAR = 'FOLLOW_BY_CAR',
  FOLLOW_BY_PLACEMENT = 'FOLLOW_BY_PLACEMENT',
}

export type FollowTarget = {
  followBy: EFollowBy;
  displayNumber: number;
};

export type CameraTarget = {
  isPredetermined: boolean;
  isAutoDirector?: boolean;
  index: number;
  type: string;
};

const ControlPanelPage: React.FC = () => {
  const router = useIonRouter();
  const authUser = useAuthUserStore((state) => state.authUser);

  const [logLevel] = useState<'debug' | 'info' | 'silent'>('silent');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [udpConfig, setUDPConfig] = useState<UDPConfig>();
  const [contest, setContest] = useState<Contest>();

  const [isConnected, setIsConnected] = useState<StatusOptions>(StatusOptions.Unknown);
  const [followTarget, setFollowTarget] = useState<FollowTarget | undefined>();
  const [cameraTarget, setCameraTarget] = useState<CameraTarget | undefined>();
  const [udpClient, setUDPClient] = useState<UDPConnector>();
  const [selectedRace, setSelectedRace] = useState<Race>();
  const [currentRaceId, setCurrentRaceId] = useState<number | undefined>();

  const [apiClient, setAPIClient] = useState<APITriggerClient>();
  const [buttonAPITriggers, setButtonAPITriggers] = useState<ButtonAPITrigger[]>([]);

  const [udpMessageCount, setUdpMessageCount] = useState<number>(0);
  const [startLightCountdown, setStartLightCountdown] = useState<number>(0);
  const [gameplayStageInfo, setGameplayStageInfo] = useState<GameplayStageInfoUpdate>();
  const [gameplayStageLive, setGameplayStageLive] = useState<GameplayStageLiveUpdate>();
  const [cars, setCars] = useState<CarLiveUpdate[]>([]);
  const [oldParticipantCount, setOldParticipantCount] = useState<number>(0);
  const [playersInRace, setPlayersInRace] = useState<Player[]>([]);

  // Config for UDP Client on modal submit
  const handleServerConfigSubmit = (config: UDPConfig, contest?: Contest, apiClient?: APITriggerClient) => {
    setContest(contest);
    setUDPConfig(config);
    setAPIClient(apiClient);

    const _udpClient = makeUDPConnector(handleIncomingMessage, config);
    setUDPClient(_udpClient);
    setIsModalOpen(false);
  };

  const handleIncomingMessage = (msg: UDPMessage) => {
    // setLatestUDPMessage(msg);
    setUdpMessageCount((udpMessageCount) => udpMessageCount + 1);

    // Sync incoming data to state
    if (msg.messageType === EMessageType.GameplayStageInfoUpdate) setGameplayStageInfo(msg.data);
    if (msg.messageType === EMessageType.GameplayStageLiveUpdate) setGameplayStageLive(msg.data);
    if (msg.messageType === EMessageType.StartLightUpdate) setStartLightCountdown(msg.data.startLightCountdown);

    // Handle CarLiveUpdate by updating the cars collection
    if (msg.messageType === EMessageType.CarLiveUpdate) {
      // If the car already exists in the array, update it. Otherwise, add it.
      setCars((prevState) => {
        const carIndex = prevState.findIndex((car) => car.carNumber === msg.data.carNumber);
        if (carIndex !== -1) prevState[carIndex] = msg.data;
        else {
          prevState.push(msg.data);
          prevState = prevState.sort((a, b) => a.carNumber - b.carNumber);
        }
        return [...prevState];
      });
    }
  };

  useEffect(() => {
    supabase
      .from('CurrentRace')
      .select('*, Race(*)')
      .single()
      .then((res) => {
        if (res.data?.race) setCurrentRaceId(res.data?.race);
      });

    const currentRaceSubscription = subscribeToCurrentRace(async (payload) => {
      const { new: newRace } = payload;
      if (!newRace) return;
      if (logLevel === 'debug') console.log('CurrentRace changed: ' + newRace.race);
      setCurrentRaceId(newRace.race);
    });

    return () => void currentRaceSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (cars.length !== oldParticipantCount) setOldParticipantCount(cars.length);
  }, [cars, oldParticipantCount]);

  useEffect(() => {
    if (!selectedRace?.id && !currentRaceId) return;
    supabase
      .from('Player')
      .select('*, RaceParticipant!inner (*)')
      .eq('RaceParticipant.race', selectedRace?.id ?? currentRaceId)
      .then((res) => {
        if (logLevel === 'debug') {
          if (selectedRace?.id) console.log('Using selected race with id: ', selectedRace?.id);
          else if (currentRaceId) console.log('Using current race with id: ', currentRaceId);
          console.log('All players with a matching car number: ', res.data);
        }

        if (!res?.data || res.data.length < 1) return;
        setPlayersInRace(res.data);
      });
  }, [oldParticipantCount, selectedRace, currentRaceId]);

  useEffect(() => {
    if (!apiClient) return;
    fetchButtonAPITriggers(apiClient.id).then((res) => {
      if (res.error) return console.error(res.error);
      setButtonAPITriggers(res.data as ButtonAPITrigger[]);
    });
  }, [apiClient]);

  useEffect(() => {
    if (!gameplayStageLive) return;
    // TODO: this might happen VERY often, so we should probably debounce it as an interval or something like that.
    if (selectedRace?.id) {
      void updateRaceCountdown(
        selectedRace.id,
        startLightCountdown,
        gameplayStageLive.timeLeft,
        gameplayStageLive.lapsLeft,
        gameplayStageInfo?.gameplayStageName ?? ''
      );
    }
  }, [startLightCountdown, gameplayStageLive]);

  /** Parses UDP traffic, and updates the DB repeatedly */
  useEffect(() => {
    const gameStartInternal = setInterval(() => {
      // Store updated references to the race and cars in state. Has to be done in this weird way..
      let tmpCars = cars;
      let raceId = selectedRace?.id;
      let tmpPlayers = playersInRace;
      let tmpGameplayStageName = gameplayStageInfo?.gameplayStageName;

      setCars((c) => {
        tmpCars = c;
        return c;
      });

      setSelectedRace((r) => {
        raceId = r?.id;
        return r;
      });

      setPlayersInRace((p) => {
        tmpPlayers = p;
        return p;
      });

      setGameplayStageInfo((gs) => {
        tmpGameplayStageName = gs?.gameplayStageName;
        return gs;
      });

      // If we haven't chosen a race, we won't be trying to patch a leaderboard!
      if (!raceId) return;

      // Find the participant ID for each car number in our cars state
      tmpCars?.forEach(async (car: CarLiveUpdate) => {
        const player = tmpPlayers?.find((p) => p.car_number === car.carNumber) as any;
        if (!player) return console.warn('No player found for car number', car.carNumber);
        const participantId = player?.RaceParticipant?.find((part: any) => part.race === raceId)?.id;
        if (!participantId) return console.warn('No participant found for player', player.id);
        if (!tmpGameplayStageName) return console.warn('No gameplay stage name found!');

        await supabase.from('LeaderboardEntry').upsert(
          {
            participant: participantId,
            stage: tmpGameplayStageName,
            placement: car.position,
            completed_laps: car.completedLaps,
            lap_progress: car.lapProgress,
            best_lap_time: car.bestLap.time,
            best_lap_sectors: car.bestLap.sectors,
            current_lap_time: car.currentLap.time,
            current_lap_sectors: car.currentLap.sectors,
            last_lap_time: car.lastLap.time,
            last_lap_sectors: car.lastLap.sectors,
            gap_to_leader: car.gAP ?? car.gap ?? 0,
            interval_to_next: car.interval,
            in_pit: car.inPitStatus,
            is_disqualified: car.disqualified,
            speed: car.speed,
            engine_rpm: car.engineRPM,
            gear: car.gear,
            total_time: car.totalTime,
            penalties: [
              ...car.servedPenalties.map((p) => ({
                ...p,
                isServed: true,
              })),
              ...car.pendingPenalties.map((p) => ({ ...p, isServed: false })),
            ],
          },
          { ignoreDuplicates: false }
        );
      });
    }, 150);
    return () => clearInterval(gameStartInternal);
  }, []);

  const handleStartStream = async (race: Race | undefined, disconnect: boolean) => {
    setSelectedRace(race);

    // Set CurrentRace in DB
    if (race) await supabase.from('CurrentRace').upsert({ id: 1, race: race.id });

    if (disconnect) disconnectStream();
    else startStream();
  };

  const startStream = () => {
    setCars([]);
    setIsConnected(StatusOptions.Available);
  };

  const disconnectStream = () => {
    setCars([]);
    setIsConnected(StatusOptions.Unknown);
  };

  const triggerButtonAPI = async (type: EButtonAPITriggerTypes, index: number) => {
    setTimeout(async () => {
      let tmpCars = cars;
      let tmpFollowTarget = followTarget;

      setFollowTarget((ft) => {
        tmpFollowTarget = ft;
        return ft;
      });

      setCars((c) => {
        tmpCars = c;
        return c;
      });

      if (logLevel !== 'silent') console.log(`You clicked button: ${type} | Index: ${index}`);
      const url = buttonAPITriggers.find((t) => t.button_type === type && t.button_index === index)?.target_url;
      if (!url) {
        if (logLevel === 'debug') console.log('The button had no URL trigger attached.');
        return;
      }

      const carNumber =
        tmpFollowTarget?.followBy === EFollowBy.FOLLOW_BY_CAR
          ? tmpFollowTarget?.displayNumber
          : tmpCars.find((c) => c.position === tmpFollowTarget?.displayNumber)?.carNumber;

      const player = playersInRace.find((p) => p.car_number === carNumber);
      if (!player) return console.warn('No player found for the car number', carNumber);
      else if (logLevel === 'debug') console.log('Player found for the car number', carNumber, 'with id ', player.id);
      const urlWPid = url?.replace('$pid$', player.id.toString() ?? '');

      if (logLevel === 'debug') console.log(`Triggering ${urlWPid}...`);
      /* This is meant to trigger an HTTP action, and we do NOT expect the source to return anything really. */
      try {
        void fetch(urlWPid, { method: 'GET', mode: 'no-cors' }).catch();
      } catch (e) {
        // Do nothing
      }
    }, 25);
  };

  const handleSwitchFollowing = (follow: FollowTarget) => {
    setFollowTarget(follow);

    // Send UDP message to follow the target
    const targetNum = follow.followBy === EFollowBy.FOLLOW_BY_CAR ? follow.displayNumber : cars.find((c) => c.position === follow.displayNumber)?.carNumber;
    if (!targetNum) return;
    udpClient?.sendUDPMessage(msgFollowPlayer(targetNum));
  };

  const handleSwitchCamera = (camera: CameraTarget) => {
    setCameraTarget(camera);

    const shouldEnableAutoDirector = !camera.isPredetermined && camera.isAutoDirector === true;

    // Manage auto director
    udpClient?.sendUDPMessage(msgAutoDirector(shouldEnableAutoDirector));
    if (!shouldEnableAutoDirector) udpClient?.sendUDPMessage(msgChangeCamera(camera.index, camera.type));
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data }) => {
      if (!data.session) router.push('/login');
    });
  }, [router, authUser]);
  return (
    <PageWrapper>
      <ConnectionModal isModalOpen={isModalOpen} onModalClose={() => setIsModalOpen(false)} onSubmit={handleServerConfigSubmit} />
      <FabSection onRefresh={() => udpConfig && handleServerConfigSubmit(udpConfig)} onSettings={() => setIsModalOpen(true)} />

      <div className={styles.paddedSides}>
        <InitializerSection isConnected={isConnected} onRaceActiveChange={handleStartStream} contest={contest} />
        <p className="inline mt-1 self-center justify-center m-auto text-center">Message count: {udpMessageCount}</p>
      </div>

      <div className={`${styles.paddedSides} bg-secondary pb-10 pt-2 mt-8`}>
        <FollowBySections
          isConnected={true}
          carNumbers={cars.map((car) => car.carNumber) ?? carNumbers}
          playerPlacements={playerPlacements.slice(0, cars.length)}
          onFollowChange={(target, buttonIndex) => {
            handleSwitchFollowing(target);
            void triggerButtonAPI(
              target.followBy === EFollowBy.FOLLOW_BY_PLACEMENT ? EButtonAPITriggerTypes.ByPlacement : EButtonAPITriggerTypes.ByCarNumber,
              buttonIndex
            );
          }}
          activeTarget={followTarget}
        />
      </div>

      <div className={styles.paddedSides}>
        <CameraSection
          isConnected={true}
          cameras={cameras}
          activeTarget={cameraTarget}
          onFollowCamera={(camera, buttonIndex) => {
            handleSwitchCamera(camera);
            void triggerButtonAPI(EButtonAPITriggerTypes.Camera, buttonIndex);
          }}
        />
        <SpecialActionsSection
          isConnected={true}
          cars={cars}
          activeFollow={followTarget}
          activeCamera={cameraTarget}
          onFollowTarget={handleSwitchFollowing}
          onCameraTarget={handleSwitchCamera}
          onSpecialActionAPITrigger={(index) => triggerButtonAPI(EButtonAPITriggerTypes.SpecialAction, index)}
        />
      </div>
    </PageWrapper>
  );
};
export default ControlPanelPage;
