import React, { useCallback } from 'react';
import { IonCol, IonGrid, IonIcon, IonRow, useIonAlert } from '@ionic/react';
import { carSportOutline, expandOutline, keyOutline, megaphoneOutline, personOutline } from 'ionicons/icons';
import { Tooltip } from 'antd';
import { Player } from './ManagePlayerSection';
import supabase from 'services/supabase-config';

type ManagePlayerCardProps = {
  player: Player;
  onDidUpdateDB: () => void;
};

const ManagePlayerCard: React.FC<ManagePlayerCardProps> = ({ player, onDidUpdateDB }) => {
  const [presentAlert] = useIonAlert();

  const handleInputTexts = useCallback(async () => {
    await presentAlert({
      header: 'Update player description',
      subHeader: `You are editing the description text for player with id ${player.id}.`,
      cssClass: 'custom-alert-dialog',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Update Texts',
          role: 'confirm',
          handler: postTextData,
        },
      ],
      inputs: [
        {
          name: 'player_description',
          label: 'Header',
          type: 'text',
          value: player.player_description,
          placeholder: '..',
        },
      ],
    });
  }, [player, presentAlert]);

  const postTextData = async (value: any) => {
    const description = value['player_description'];
    await supabase.from('Player').update({ player_description: description }).eq('id', player.id);
    onDidUpdateDB();
  };

  return (
    <div className="bg-primary h-[50px] min-h-[50px] rounded flex flex-row items-center my-1 overflow-hidden">
      <IonGrid className="px-0">
        <Tooltip placement="bottomLeft" title="Click to edit this players description" className="p-2 rounded hover:bg-tertiary cursor-pointer">
          <IonRow onClick={handleInputTexts} className="w-full px-2">
            <IonCol className="flex items-center overflow-hidden" size="11.5">
              <div className="w-[90px] max-w-[90px] min-w-[90px] flex flex-row">
                <IonIcon icon={keyOutline} className="mr-2 text-lg" />
                <p className="font-bold">{player.id}</p>
              </div>
              <div className="w-[90px] max-w-[90px] min-w-[90px] flex flex-row">
                <IonIcon icon={carSportOutline} className="mr-2 text-lg" />
                <p className="font-bold">{player.car_number}</p>
              </div>
              <div className="flex flex-row mr-2">
                <IonIcon icon={personOutline} className="mr-2 text-lg" />
                <p className="w-[250px] max-w-[250px] min-w-[250px] truncate">{player?.display_name}</p>
              </div>
              <div className="flex flex-row mr-2">
                <IonIcon icon={megaphoneOutline} className="mr-2 text-lg" />
                <p className="w-[250px] max-w-[250px] min-w-[250px] truncate">{player?.player_description}</p>
              </div>
            </IonCol>
            <IonCol size="0.5" className="flex justify-start">
              <IonIcon icon={expandOutline} className="text-xl" />
            </IonCol>
          </IonRow>
        </Tooltip>
      </IonGrid>
    </div>
  );
};
export default ManagePlayerCard;
