import { createClient } from '@supabase/supabase-js';
import type { Database } from 'types/database.types';
import { ESupabaseSettings } from 'settings';

const supabase = createClient<Database>(ESupabaseSettings.URL, ESupabaseSettings.PUBLIC_KEY);

export const supabaseAnonClient = createClient<Database>(ESupabaseSettings.URL, ESupabaseSettings.PUBLIC_KEY);

// These helpers allow me to get the type of single table rows by its name
export type Tables<T extends keyof Database['public']['Tables']> = Database['public']['Tables'][T]['Row'];
export type Enums<T extends keyof Database['public']['Enums']> = Database['public']['Enums'][T];

export type Player = Database['public']['Tables']['Player']['Row'];
export type Stage = Database['public']['Tables']['Stage']['Row'];
export type RaceCountdown = Database['public']['Tables']['RaceCountdown']['Row'];
export type RaceParticipant = Tables<'RaceParticipant'>;

// Helper to get the type of single array elements
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;

// Helper to get the type of a single row when joining
export function isArrayOf<T>(arrOrObj: unknown, typeGuard: (item: unknown) => item is T): arrOrObj is T[] {
  return Array.isArray(arrOrObj) && arrOrObj.every(typeGuard);
}
/** Usage Example when from('Race').select('*, Stage (*)') is called, and ts won't let us do data.Stage.name.
if (isArrayOf(race.Stage, (item: any): item is Stage => 'name' in item)) {
  console.log(race.Stage.name); // Typescript knows that Stage is an object, not an array of Stages.
};
 */
export default supabase;
