import { ConfigProvider, theme } from 'antd';
import { ThemeConfig } from 'antd/lib/config-provider/context';

export const ThemeAccentWrapper: React.FC = ({ children }) => {
  const themeConfig: ThemeConfig = {
    algorithm: [theme.darkAlgorithm],
    token: {
      colorPrimary: '#f57c00',
      colorBgBase: '#1a2138',
      //colorBgBase: '#173a17',
      borderRadius: 4,
    },
  };

  return <ConfigProvider theme={themeConfig}>{children}</ConfigProvider>;
};
