import { useCallback, useEffect } from 'react';
import { useIonAlert, useIonRouter } from '@ionic/react';
import { useAuthUserStore } from 'state/user';
import supabase from 'services/supabase-config';

type Props = {
  onCancel: () => void;
};

const LogoutSection: React.FC<Props> = ({ onCancel }) => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const resetAuthUser = useAuthUserStore((state) => state.resetAuthUser);

  const handleSignOut = useCallback(async () => {
    await presentAlert({
      header: 'Are you sure you wish to logout?',
      cssClass: 'custom-alert-dialog',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: onCancel,
        },
        {
          text: 'Yes, logout',
          role: 'confirm',
          handler: logoutAndRedirect,
        },
      ],
    });
  }, []);

  const logoutAndRedirect = async () => {
    resetAuthUser();
    await supabase.auth.signOut();
    router.push('/');
  };

  useEffect(() => void handleSignOut(), [handleSignOut]);
  return <></>;
};
export default LogoutSection;
