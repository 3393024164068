import React, { useEffect, useState } from 'react';
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { videocam } from 'ionicons/icons';
import ButtonFilled from 'ui/components/buttons/regular-button-filled/ButtonFilled';
import { CameraTarget } from '../ControlPanelPage';
import styles from '../../shared-styles.module.css';
import { CameraSettings, fetchCameraSettings } from 'services/api-fetches';

type CameraSectionProps = {
  cameras: number[];
  activeTarget: CameraTarget | undefined;
  onFollowCamera: (camera: CameraTarget, button_index: number) => void;
  isConnected: boolean;
};

const CameraSection: React.FC<CameraSectionProps> = ({ activeTarget, onFollowCamera, isConnected }) => {
  const [cameras, setCameras] = useState<CameraSettings>();

  useEffect(() => {
    fetchCameraSettings().then((res) => {
      setCameras(res.data?.filter((c) => !c.is_special_action).sort((a, b) => a.sorting_index - b.sorting_index));
    });
  }, []);

  return (
    <>
      <h2 className={`flex justify-center ${isConnected ? styles.textEnabled : styles.textDisabled}`}>
        <IonIcon icon={videocam} className="text-2xl pr-2" />
        Select Camera
      </h2>
      <IonGrid>
        <IonRow>
          <IonCol key={0} size="2">
            <ButtonFilled
              height="full"
              isSelected={!activeTarget?.isPredetermined && activeTarget?.isAutoDirector}
              disabled={!isConnected}
              onClick={() => onFollowCamera({ isAutoDirector: true, isPredetermined: false, index: -1, type: '' }, 0)}
            >
              <span>Toggle</span>
              <br />
              <span className="underline font-normal">Auto Director</span>
            </ButtonFilled>
          </IonCol>
          {cameras?.map((camera, i) => (
            <IonCol key={camera.display_name}>
              <ButtonFilled
                height="full"
                isSelected={!activeTarget?.isPredetermined && activeTarget?.index === camera.camera_index && activeTarget?.type === camera.camera_type}
                disabled={!isConnected}
                onClick={() => onFollowCamera({ isPredetermined: false, index: camera.camera_index, type: camera.camera_type }, i + 1)}
              >
                {camera.display_name}
              </ButtonFilled>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </>
  );
};
export default CameraSection;
