import React, { useCallback, useEffect, useState } from 'react';
import styles from './manage-player-section.module.css';
import { ArrayElement, supabaseAnonClient } from 'services/supabase-config';
import ManagePlayerCard from './ManagePlayerCard';

export type Players = Awaited<ReturnType<typeof fetchPlayers>>['data'];
export type Player = ArrayElement<Players>;
const fetchPlayers = async () => supabaseAnonClient.from('Player').select('*').order('id');

const ManagePlayerSection: React.FC = () => {
  const [players, setPlayers] = useState<Players>([]);

  useEffect(() => {
    refreshDB();
  }, []);

  const refreshDB = useCallback(() => {
    fetchPlayers().then((r) => setPlayers(r.data ?? []));
  }, []);

  return (
    <div className={`flex flex-row w-full ${styles.contentArea}`}>
      <section className="w-full flex flex-col overflow-y-auto">
        {players?.map((p, i) => (
          <ManagePlayerCard key={i} player={p} onDidUpdateDB={refreshDB} />
        ))}
      </section>
    </div>
  );
};
export default ManagePlayerSection;
