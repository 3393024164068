import React from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import InfoCard from 'ui/components/cards/InfoCard/InfoCard';
import { convertToMMSS } from 'services/api-fetches';

type InfoSectionProps = {
  contestName?: string;
  stageName?: string;
  stageCountdown?: number;
  startLight?: number;
};

const InfoSection: React.FC<InfoSectionProps> = ({ contestName, stageName, stageCountdown, startLight }) => (
  <IonGrid className="mt-2">
    <IonRow>
      <IonCol size="4">
        <InfoCard className="h-full flex flex-col justify-center">
          <p className="text-center font-bold text-xl">{contestName ?? '...'}</p>
        </InfoCard>
      </IonCol>
      <IonCol size="4">
        <InfoCard className="h-full flex flex-col justify-center">
          <div className="mx-auto">
            <p>
              <span className="font-bold text-lg">Game Stage:</span> {stageName ?? '...'}
            </p>
          </div>
        </InfoCard>
      </IonCol>
      <IonCol size="2">
        <InfoCard className="h-full flex flex-col justify-center">
          <p className="text-center font-bold">
            Stage
            <br />
            Countdown:
          </p>
          <p className="text-center text-xl text-accent-base">
            {stageCountdown ? (Math.floor(stageCountdown) > 0 ? convertToMMSS(Math.floor(stageCountdown).toString(), true) : '...') : '...'}
          </p>
        </InfoCard>
      </IonCol>
      <IonCol size="2">
        <InfoCard className="h-full flex flex-col justify-center">
          <p className="text-center font-bold">
            Startlight
            <br />
            Countdown:
          </p>
          <p className="text-center text-xl text-accent-base">{startLight ? convertToMMSS(Math.floor(startLight).toString(), true) : '...'}</p>
        </InfoCard>
      </IonCol>
    </IonRow>
  </IonGrid>
);
export default InfoSection;
