import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import 'antd/dist/reset.css';

/* Tailwind & Ant-d CSS imports */
import './ui/theme/tailwind-setup.css';

/* Theme variables */
import './ui/theme/ionic-variables.css';
import './ui/theme/variables.css';
import './ui/theme/global.css';

/* i18n */
import './i18n/config';

import { ThemeAccentWrapper } from 'ui/theme/ThemeAccentWrapper';
import { LoginPage } from './ui/pages/login-page/LoginPage';
import ControlPanelPage from './ui/pages/control-panel-page/ControlPanelPage';
import LeaderboardPage from './ui/pages/leaderboard-page/LeaderboardPage';
import AdminPage from './ui/pages/admin-page/AdminPage';
import { buildMode } from './settings';
import supabase from './services/supabase-config';
import { useAuthUserStore } from './state/user';
import GfxPage from './ui/pages/gfx-page/GfxPage';

setupIonicReact({ mode: 'ios' });

// Sets up the app. Some pages are only available in desktop build mode (electron based control panel).
export const App: React.FC = () => {
  const setAuthUser = useAuthUserStore((state) => state.setAuthUser);

  useEffect(() => {
    supabase.auth.getSession().then((session) => {
      if (session.data.session?.user?.aud === 'authenticated') setAuthUser(session.data.session.user);
    });
  }, []);

  return (
    <IonApp className="bg-primary">
      <IonReactRouter>
        <ThemeAccentWrapper>
          {buildMode === 'gfx' ? (
            <Route path="/" component={GfxPage} />
          ) : (
            <>
              <Route exact path="/login" component={LoginPage} />
              {buildMode === 'web' && <Route exact path="/admin" component={AdminPage} />}
              {buildMode === 'desktop' && <Route exact path="/controller" component={ControlPanelPage} />}
              <Route exact path="/" component={buildMode === 'web' ? LeaderboardPage : ControlPanelPage} />
            </>
          )}
        </ThemeAccentWrapper>
      </IonReactRouter>
    </IonApp>
  );
};
