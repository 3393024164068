// Typed Usage Example
import supabase, { ArrayElement, supabaseAnonClient } from './supabase-config';

export type Stages = Awaited<ReturnType<typeof fetchStages>>['data'];
export type Stage = ArrayElement<Stages>;
export const fetchStages = async () => await supabase.from('Stage').select();

export type Contests = Awaited<ReturnType<typeof fetchContests>>['data'];
export type Contest = ArrayElement<Contests>;
export const fetchContests = async () => await supabase.from('Contest').select();

export type Races = Awaited<ReturnType<typeof fetchRacesForContest>>['data'];
export type Race = ArrayElement<Races>;
export const fetchRacesForContest = async (contestId: number) => await supabase.from('Race').select('*').eq('contest', contestId).order('id');

export type CameraSettings = Awaited<ReturnType<typeof fetchCameraSettings>>['data'];
export type CameraSetting = ArrayElement<CameraSettings>;
export const fetchCameraSettings = async () => await supabase.from('CameraSetting').select();
export const fetchCameraSettingByDisplayName = async (displayName: string) => await supabase.from('CameraSetting').select().eq('display_name', displayName);

export type SpecialCameraActions = Awaited<ReturnType<typeof fetchSpecialCameraActions>>['data'];
export type SpecialCameraAction = ArrayElement<SpecialCameraActions>;
export const fetchSpecialCameraActions = async () => await supabase.from('SpecialActions').select();

export type SpecialCameraActionTriggers = Awaited<ReturnType<typeof fetchButtonAPITriggers>>['data'];
export type SpecialCameraActionTrigger = ArrayElement<SpecialCameraActionTriggers>;

export type ButtonAPITriggers = Awaited<ReturnType<typeof fetchButtonAPITriggers>>['data'];
export const fetchButtonAPITriggers = async (clientID: number) => await supabase.from('ButtonAPITriggers').select().eq('api_http_client', clientID);

export type APITriggerClients = Awaited<ReturnType<typeof fetchAPITriggerClients>>['data'];
export type APITriggerClient = ArrayElement<APITriggerClients>;
export const fetchAPITriggerClients = async () => await supabase.from('APIClient').select();

export type RacesInfo = Awaited<ReturnType<typeof fetchRaceInfo>>['data'];
export type RaceInfo = ArrayElement<RacesInfo>;
export const fetchRaceInfo = async (id: number) => await supabase.from('Race').select('*, Contest (*), RaceCountdown (*)').eq('id', id);
export const fetchAllRacesInfo = async () => await supabase.from('Race').select('*, Contest (*, Season (*)), RaceCountdown (*)');

export const fetchRacesInfoForContest = async (contestId: number) =>
  await supabase.from('Race').select('*, Contest (*, Season (*)), RaceCountdown (*)').eq('contest', contestId);

export type RaceParticipants = Awaited<ReturnType<typeof fetchParticipants>>['data'];
export type RaceParticipant = ArrayElement<RaceParticipants>;
export const fetchParticipants = async (id: number) =>
  await supabase.from('RaceParticipant').select('*, Player (*)').eq('race', id).order('player', { ascending: true });

export const fetchParticipantsForRace = async (raceId: number) => {
  const { data } = await supabase.from('RaceParticipant').select('*, Player (*)').eq('race', raceId);
  return data?.flatMap((participant) => participant.Player) ?? [];
};

export const fetchParticipantsForCurrentRace = async () => {
  const { data } = await supabase.from('CurrentRace').select().single();
  if (!data) return [];
  return await fetchParticipantsForRace(data.race);
};

export const updateRaceCountdown = async (raceId: number, startLightCountdown: number, timeLeft: number, lapsLeft: number, gameplayStageName: string) => {
  await supabase
    .from('RaceCountdown')
    .upsert({
      race: raceId,
      start_light: startLightCountdown,
      stage_time_left: timeLeft,
      laps_left: lapsLeft,
      stage: gameplayStageName,
    })
    .eq('race', raceId);
};

export type ActiveRaceLeaderboard = Awaited<ReturnType<typeof fetchFullCurrentLeaderboard>>;
export const fetchFullCurrentLeaderboard = async () => {
  const { data, error } = await supabaseAnonClient
    .from('CurrentRace')
    .select('*, Race (*, RaceCountdown (*), Contest(*), RaceParticipant (*, LeaderboardEntry (*), Player (*, Team (*))))')
    .single();

  if (error) console.error(error);

  return data;
};

export const fetchFullLeaderboardForRace = async (raceId: number) => {
  return await supabaseAnonClient
    .from('Race')
    .select('*, RaceCountdown (*), Contest(*), RaceParticipant (*, LeaderboardEntry (*), Player (*, Team (*)))')
    .eq('id', raceId)
    .single();
};

// Input format is "0.924242" for 01:32:424
export const convertToMMSS = (string: string, simplify = false) => {
  // Convert the input string to a number
  let seconds = Number(string);
  if (seconds <= 0) return '00:00:000';

  // Calculate the number of minutes by dividing the total seconds by 60 and rounding down
  const minutes: number = Math.floor(seconds / 60);
  seconds = seconds % 60;

  // Calculate the milliseconds by multiplying the remaining seconds by 1000 and rounding down
  const milliseconds = Math.floor((seconds % 1) * 1000);

  // Calculate the remaining seconds by taking the remainder of the total seconds divided by 60
  seconds = Math.floor(seconds);

  // Convert minutes and seconds to strings and add leading zeroes if necessary
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = seconds.toString().padStart(2, '0');
  const msString = milliseconds.toString().padStart(3, '0');

  // Return the formatted time string
  if (simplify) return `${minutesString}:${secondsString}`; // Simplify the time string to only show minutes and seconds (no milliseconds
  return `${minutesString}:${secondsString}:${msString}`;
};
export const convertToPercentage = (fraction: number): string => {
  return Math.round(fraction * 100).toString() + '%';
};

export const convertRaceToLabel = (raceInfo: RaceInfo, full = true): string => {
  const tmp = raceInfo as any;
  if (full) return `${tmp.Contest.Season.name} - ${tmp.Contest.name} - ${tmp.title}`;
  else return `${tmp.title}`;
};

export enum EButtonAPITriggerTypes {
  'ByPlacement' = 'ByPlacement',
  'ByCarNumber' = 'ByCarNumber',
  'Camera' = 'Camera',
  'SpecialAction' = 'SpecialAction',
}

export type ButtonAPITrigger = {
  button_type: EButtonAPITriggerTypes;
  button_index: number;
  target_url?: string;
};
