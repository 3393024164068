import React, { useCallback } from 'react';
import { IonCol, IonGrid, IonIcon, IonRow, useIonAlert } from '@ionic/react';
import { expandOutline, megaphoneOutline } from 'ionicons/icons';
import { Tooltip } from 'antd';
import { Subt } from './SubtSection';
import supabase from 'services/supabase-config';

type SubtCardProps = {
  subt: Subt;
  onDidUpdateDB: () => void;
};

const SubtCard: React.FC<SubtCardProps> = ({ subt, onDidUpdateDB }) => {
  const [presentAlert] = useIonAlert();

  const handleInputTexts = useCallback(async () => {
    await presentAlert({
      header: 'Update texts',
      subHeader: `You are editing the texts for subt with id ${subt.id}.`,
      cssClass: 'custom-alert-dialog',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Update Texts',
          role: 'confirm',
          handler: postTextData,
        },
      ],
      inputs: [
        {
          name: 'display_name',
          label: 'Header',
          type: 'text',
          value: subt.display_name,
          placeholder: '..',
        },
      ],
    });
  }, [subt, presentAlert]);

  const postTextData = async (value: any) => {
    const displayName = value['display_name'];
    await supabase.from('Subt').update({ display_name: displayName }).eq('id', subt.id);
    onDidUpdateDB();
  };

  return (
    <div className="bg-primary h-[50px] min-h-[50px] rounded flex flex-row items-center my-1 overflow-hidden">
      <IonGrid className="px-0">
        <Tooltip placement="bottomLeft" title="Click to edit this subt" className="p-2 rounded hover:bg-tertiary cursor-pointer">
          <IonRow onClick={handleInputTexts} className="w-full px-2">
            <IonCol className="flex items-center overflow-hidden" size="11.5">
              <div className="w-[90px] max-w-[90px] min-w-[90px] flex flex-row">
                <p className="font-bold">SUBT-{subt.id}</p>
              </div>
              <div className="flex flex-row mr-2">
                <IonIcon icon={megaphoneOutline} className="mr-2 text-lg" />
                <p className="w-[250px] max-w-[250px] min-w-[250px] truncate">{subt?.display_name}</p>
              </div>
            </IonCol>
            <IonCol size="0.5" className="flex justify-start">
              <IonIcon icon={expandOutline} className="text-xl" />
            </IonCol>
          </IonRow>
        </Tooltip>
      </IonGrid>
    </div>
  );
};
export default SubtCard;
