import React, { useCallback, useEffect, useState } from 'react';
import styles from './subt-section.module.css';
import { ArrayElement, supabaseAnonClient } from 'services/supabase-config';
import SubtCard from './SubtCard';

export type Subts = Awaited<ReturnType<typeof fetchSubts>>['data'];
export type Subt = ArrayElement<Subts>;
const fetchSubts = async () => supabaseAnonClient.from('Subt').select('*').order('id');

const SubtSection: React.FC = () => {
  const [subts, setSubts] = useState<Subts>([]);

  useEffect(() => {
    refreshDB();
  }, []);

  const refreshDB = useCallback(() => {
    fetchSubts().then((r) => setSubts(r.data ?? []));
  }, []);

  return (
    <div className={`flex flex-row w-full ${styles.contentArea}`}>
      <section className="w-full flex flex-col overflow-y-auto">
        {subts?.map((s, i) => (
          <SubtCard key={i} subt={s} onDidUpdateDB={refreshDB} />
        ))}
      </section>
    </div>
  );
};
export default SubtSection;
